import Modal from "components/Modal";
import { Button } from "components/standardElements";
import { useEffect, useState } from "react";
import { useAuth } from "database/useAuth";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import { COLORS, CONSTANTS } from "helpers/designConstants";
import useDistributorCollaboratorSearch from "database/useDistributorCollaboratorSearch";

const ProjectDetailsModal = ({
  isOpen,
  onClose,
  title,
  buttonTitle,
  initialValues,
  onSave,
}) => {
  const [projectName, setProjectName] = useState(initialValues?.name || "");
  const [distName, setDistName] = useState(initialValues?.distributor || null);
  const [lockAcctNumber, setLockAcctNumber] = useState(
    initialValues?.accountNumber ? true : false,
  );
  const [acctNumber, setAcctNumber] = useState(
    initialValues?.accountNumber || "",
  );
  const [distRep, setDistRep] = useState(initialValues?.rep || null);
  const [collaborators, setCollaborators] = useState(
    initialValues?.collaborators || [],
  );

  const { user } = useAuth();
  const {
    distSearchResults,
    distRepSearchResults,
    collaboratorsSearchResults,
    collaboratorsByIDs,
  } = useDistributorCollaboratorSearch();

  useEffect(() => {
    if (initialValues == null) return;
    setProjectName(initialValues.name || "");
    setDistName(initialValues.distributor || null);
    setAcctNumber(initialValues.accountNumber || "");
    setLockAcctNumber(initialValues.accountNumber ? true : false);
    setDistRep(initialValues.rep || null);

    if (initialValues.collaborators?.length > 0) {
      const prunedCollaborators = initialValues.collaborators.filter(
        (id) => id !== user.uid,
      );
      collaboratorsByIDs(prunedCollaborators).then((results) => {
        setCollaborators(
          results.map((result) => ({ value: result.id, label: result.name })),
        );
      });
    } else {
      setCollaborators([]);
    }
  }, [collaboratorsByIDs, initialValues, user.uid]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSave(projectName, distName, acctNumber, distRep, [
      user.uid,
      ...collaborators.map((c) => c.value),
    ]);
    setProjectName("");
    setDistName(null);
    setDistRep(null);
    setCollaborators([]);
    onClose();
  };

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      title={title}
      disableCloseOnOutsideClick
    >
      <form className="flex flex-col items-center gap-24" onSubmit={onSubmit}>
        <div className="flex w-full flex-col gap-4 p-4 sm:w-[400px]">
          <div className="flex flex-col">
            <div className="pl-1 text-sm uppercase text-text">
              <span className="pr-2 font-semibold">Project Name</span>
              <span className="text-xs">(required)</span>
            </div>
            <input
              type="text"
              autoFocus
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Project Name"
              className="border-1 focus-within: grid grid-cols-[auto_1fr_auto] items-center rounded-lg border border-secondary bg-white px-3 py-2 text-[18px] text-text placeholder:text-base placeholder:text-secondary focus-within:border-st-orange focus-within:outline focus-within:outline-1 focus-within:outline-st-orange hover:border-st-orange lg:max-w-lg"
            />
          </div>
          <div className="flex flex-col">
            <div className="pl-1 text-sm uppercase text-text">
              <span className="pr-2 font-semibold">Distributor</span>
              <span className="text-xs">(optional)</span>
            </div>
            <AsyncCreatableSelect
              loadOptions={(searchTerm) => distSearchResults(searchTerm)}
              cacheOptions
              defaultOptions
              openMenuOnFocus
              value={
                distName != null && distName !== "xxxx"
                  ? {
                      value: {
                        distributor: distName,
                        accountNumber: acctNumber,
                      },
                      label: distName,
                    }
                  : null
              }
              onChange={(selection) => {
                console.log(selection);
                setDistName(
                  selection.__isNew__
                    ? selection.value
                    : selection?.value?.distributor || null,
                );
                setAcctNumber(selection?.value?.accountNumber || "");
                setLockAcctNumber(
                  selection?.value?.accountNumber ? true : false,
                );
              }}
              placeholder="Search or create..."
              isClearable
              noOptionsMessage={() => "Type to search or create..."}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "lightgray",
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  color: COLORS.text,
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "5px 0",
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${COLORS.stormtechOrange}`
                    : "lightgray",
                  borderColor: state.isFocused
                    ? COLORS.stormtechOrange
                    : "lightgray",
                  borderRadius: CONSTANTS.borderRadius,
                  "&:hover": {
                    borderColor: COLORS.stormtechOrange,
                  },
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: COLORS.text,
                  fontSize: "18px",
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  "z-index": 5,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isSelected
                    ? COLORS.stormtechOrange
                    : COLORS.text,
                  fontWeight: state.isSelected ? "bold" : "normal",
                  backgroundColor: state.isFocused ? COLORS.tertiary : "white",
                }),
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="pl-1 text-sm uppercase text-text">
              <span className="pr-2 font-semibold">Acct #</span>
              <span className="text-xs">(optional)</span>
            </div>
            <input
              disabled={lockAcctNumber}
              type="text"
              value={acctNumber}
              onChange={(e) => setAcctNumber(e.target.value)}
              placeholder="Account Number"
              className={`border-1 grid grid-cols-[auto_1fr_auto] items-center rounded-lg border border-secondary bg-white px-3 py-2 text-[18px] ${
                lockAcctNumber
                  ? "border-gray-300 bg-gray-100 text-gray-500"
                  : "text-text placeholder:text-base placeholder:text-secondary focus-within:border-st-orange focus-within:outline focus-within:outline-1 focus-within:outline-st-orange hover:border-st-orange"
              } lg:max-w-lg`}
            />
          </div>
          <div className="flex flex-col">
            <div className="pl-1 text-sm uppercase text-text">
              <span className="pr-2 font-semibold">Rep</span>
              <span className="text-xs">(optional)</span>
            </div>
            <AsyncCreatableSelect
              loadOptions={(searchTerm) =>
                distRepSearchResults(searchTerm, "rep")
              }
              cacheOptions
              defaultOptions
              openMenuOnFocus
              value={
                distRep != null && distRep !== "xxxx"
                  ? { value: distRep, label: distRep }
                  : null
              }
              onChange={(selection, actionType) => {
                setDistRep(selection?.value || null);
              }}
              isClearable
              placeholder="Search or create..."
              noOptionsMessage={() => "Type to search or create..."}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "lightgray",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "5px 0",
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${COLORS.stormtechOrange}`
                    : "lightgray",
                  borderColor: state.isFocused
                    ? COLORS.stormtechOrange
                    : "lightgray",
                  borderRadius: CONSTANTS.borderRadius,
                  "&:hover": {
                    borderColor: COLORS.stormtechOrange,
                  },
                }),
                singleValue: (baseStyles, state) => ({
                  ...baseStyles,
                  color: COLORS.text,
                  fontSize: "18px",
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  "z-index": 5,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isSelected
                    ? COLORS.stormtechOrange
                    : COLORS.text,
                  fontWeight: state.isSelected ? "bold" : "normal",
                  backgroundColor: state.isFocused ? COLORS.tertiary : "white",
                }),
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="pl-1 text-sm uppercase text-text">
              <span className="pr-2 font-semibold">Collaborators</span>
              <span className="text-xs">(optional)</span>
            </div>
            <AsyncSelect
              loadOptions={(searchTerm) =>
                collaboratorsSearchResults(searchTerm, user.uid)
              }
              cacheOptions
              defaultOptions
              openMenuOnFocus
              isMulti
              value={collaborators}
              onChange={(selection, actionType) => {
                // console.log(selection, actionType);
                setCollaborators(selection);
              }}
              placeholder="Search..."
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "lightgray",
                }),
                input: (baseStyles, state) => ({
                  ...baseStyles,
                  color: COLORS.text,
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: "5px 0",
                  boxShadow: state.isFocused
                    ? `0 0 0 1px ${COLORS.stormtechOrange}`
                    : "lightgray",
                  borderColor: state.isFocused
                    ? COLORS.stormtechOrange
                    : "lightgray",
                  borderRadius: CONSTANTS.borderRadius,
                  "&:hover": {
                    borderColor: COLORS.stormtechOrange,
                  },
                }),
                multiValue: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: COLORS.tertiary,
                  color: COLORS.text,
                }),
                multiValueRemove: (baseStyles, state) => ({
                  ...baseStyles,
                  color: COLORS.text,
                  "&:hover": {
                    backgroundColor: COLORS.stormtechOrange,
                    color: "white",
                  },
                }),
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  "z-index": 5,
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  color: state.isSelected
                    ? COLORS.stormtechOrange
                    : COLORS.text,
                  fontWeight: state.isSelected ? "bold" : "normal",
                  backgroundColor: state.isFocused ? COLORS.tertiary : "white",
                }),
              }}
            />
          </div>
        </div>
        <Button disabled={projectName.length === 0} type="submit">
          {buttonTitle}
        </Button>
      </form>
    </Modal>
  );
};

export default ProjectDetailsModal;

import StandardLayout from "components/StandardLayout";
import { useParams } from "react-router-dom";
import LeftMenu from "./leftMenu/LeftMenu";
import ProductPickerModal from "./ProductPickerModal";
import ProjectDetailsModal from "components/ProjectDetailsModal";
import { useEffect, useMemo, useState } from "react";
import { doc, getFirestore, onSnapshot, updateDoc } from "firebase/firestore";
import { getSearchClient } from "services/typesense";
import { useDatabaseReader } from "database/useDatabaseReader";
import ColorCard from "components/ColorCard";
import { useCopyToClipboard } from "helpers/useCopyToClipboard";
import { onUpdateProjectSearchableData } from "database/projectsWriter";
import { ClipboardPaste, CopyPlus, Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import { useProjectBuilderInventory } from "./useProjectBuilderInventory";
import ConfirmModal from "components/ConfirmModal";
import { onDeleteProject } from "database/projectsWriter";
import useNavigation from "helpers/useNavigation";

export const ProjectBuilderPage = () => {
  const { projID } = useParams();
  const [, copy] = useCopyToClipboard();
  const [navigate] = useNavigation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { warehouses, articleSearchKey } = useDatabaseReader();
  const [showProductPicker, setShowProductPicker] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [project, setProject] = useState();
  const [projectItems, setProjectItems] = useState([]);

  const { addArticleForInventory, inventory, inventoryLoading } =
    useProjectBuilderInventory(projID, articleSearchKey, warehouses);

  const sharableLink = useMemo(() => {
    let domain = "";
    if (
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("192.168") ||
      window.location.hostname.includes("bs-local") ||
      window.location.hostname.includes("scotts-macbook-pro-2023.local")
    ) {
      domain = `http://${window.location.hostname}:${window.location.port}`;
    } else {
      domain = `https://${window.location.hostname}`;
    }

    return domain.concat(`/public/sharing/${projID}`);
  }, [projID]);

  useEffect(() => {
    const db = getFirestore();

    const unsub = onSnapshot(doc(db, "projects", projID), (doc) => {
      if (!doc.exists()) return;
      setProject({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt?.toDate(),
        modifiedAt: doc.data().modifiedAt?.toDate(),
      });
    });

    return () => unsub;
  }, [projID]);

  useEffect(() => {
    if (project == null || articleSearchKey == null) return;
    const searchParameters = {
      q: "*",
      query_by: "itemNumber",
      filter_by: `id: [${project.selectedItems
        ?.map((item) => item.id)
        ?.join(", ")}]`,
      sort_by: "itemNumber:asc",
      page: 1,
      per_page: 240,
    };
    const searchClient = getSearchClient(articleSearchKey);
    const collection = ["local"].includes(
      process.env.REACT_APP_USER_ENVIRONMENT,
    )
      ? "staging.articles"
      : "production.articles";
    searchClient
      .collections(collection)
      .documents()
      .search(searchParameters)
      .then((results) => {
        const items = results.hits.map((hit) => hit.document);
        setProjectItems(items);
      });
  }, [project, articleSearchKey]);

  const onSelectItem = async (id) => {
    if (project == null) return;

    let currentSelections = project.selectedItems
      ? [...project.selectedItems]
      : [];

    if (currentSelections.find((selection) => selection.id === id)) {
      currentSelections = currentSelections.filter((item) => item.id !== id);
    } else {
      addArticleForInventory(id);
      currentSelections.push({ id: id, dateAdded: new Date() });
    }

    try {
      const db = getFirestore();
      await updateDoc(doc(db, "projects", projID), {
        selectedItems: currentSelections,
      });
    } catch (error) {
      console.log("Error adding item to cart", error);
    }
  };

  const onSaveDetails = async (
    projectName,
    distName,
    acctNumber,
    distRep,
    collaboratorIDs,
  ) => {
    console.log(projectName, distName, acctNumber, distRep, collaboratorIDs);
    onUpdateProjectSearchableData(
      projID,
      projectName,
      distName,
      acctNumber,
      distRep,
      collaboratorIDs,
    );
  };

  return (
    <StandardLayout
      isMenuOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
      leftMenuContent={
        <LeftMenu
          project={project}
          onSelectEditDetails={() => setShowEditDetails(true)}
        />
      }
    >
      <div className="grid h-full w-full grid-rows-[auto_1fr] overflow-hidden">
        <div className="z-10 flex w-full items-center justify-around gap-4 bg-white px-3 py-2 text-text shadow-md md:justify-normal">
          <img
            className="md:max-w-24 max-w-16 max-h-8 object-contain md:max-h-8"
            src={project?.logoURL || "/stormtech_icon_placeholder.svg"}
            alt="Company Logo"
          />
          <div className="h-full border-r border-secondary"></div>
          <button
            className="flex cursor-pointer items-center px-1 py-1 text-xs font-bold uppercase hover:text-st-orange md:px-3 md:text-base"
            onClick={() => setShowProductPicker(true)}
          >
            <CopyPlus className="mr-2 h-5 w-5" /> Add Items
          </button>
          <div className="h-full border-r border-secondary"></div>
          <button
            className="flex cursor-pointer items-center px-1 py-1 text-xs font-bold uppercase hover:text-st-orange md:px-3 md:text-base"
            onClick={() => {
              copy(sharableLink);
              toast.success("Copied to clipboard");
            }}
          >
            <ClipboardPaste className="mr-2 h-5 w-5" /> Copy Link
          </button>
          <div className="flex h-full grow items-center justify-end gap-4">
            <div className="h-full border-r border-secondary"></div>
            <button
              className="cursor-pointer hover:text-red-700"
              onClick={() => setShowConfirmDelete(true)}
            >
              <Trash2 />
            </button>
          </div>
        </div>
        <div className="flex h-full flex-col gap-3 overflow-auto bg-secondary p-3">
          {projectItems.map((item) => (
            <ColorCard
              key={item.id}
              productData={item}
              selectedWarehouses={project?.warehouses}
              currency={project?.currency}
              showItemNumber={true}
              liveInventory={inventory}
              isLoadingInventory={inventoryLoading}
              isSelectable={true}
              isSelected={project?.selectedItems
                ?.map((item) => item.id)
                ?.includes(item.id)}
              onSelect={onSelectItem}
            />
          ))}
        </div>
      </div>
      <ProductPickerModal
        isOpen={showProductPicker}
        onClose={() => setShowProductPicker(false)}
        selectedItems={project?.selectedItems?.map((item) => item.id)}
        onSelectItem={onSelectItem}
        project={project}
      />
      <ProjectDetailsModal
        isOpen={showEditDetails}
        onClose={() => setShowEditDetails(false)}
        title="Edit Details"
        buttonTitle="Save"
        initialValues={{
          name: project?.name,
          distributor: project?.distributor,
          accountNumber: project?.accountNumber,
          rep: project?.rep,
          collaborators: project?.users,
        }}
        onSave={onSaveDetails}
      />
      <ConfirmModal
        title="Confirm Delete"
        message={
          <div>
            <p>Are you sure you want to delete the project:</p>
            <p>{project?.name}</p>
          </div>
        }
        buttonTitle="Delete"
        show={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        isDestructive
        delayConfirm
        onConfirm={() => {
          onDeleteProject(projID);
          navigate("/private/projects", `d=${projID}`);
        }}
      />
    </StandardLayout>
  );
};

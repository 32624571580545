import { getSearchClient } from "services/typesense";
import { useDatabaseReader } from "./useDatabaseReader";
import { useState, useEffect, useCallback } from "react";

const useDistributorCollaboratorSearch = () => {
  const { userSearchKey, projectSearchKey } = useDatabaseReader();
  const [projectsClient, setProjectsClient] = useState();
  const [usersClient, setUsersClient] = useState();
  const env = ["local"].includes(process.env.REACT_APP_USER_ENVIRONMENT)
    ? "staging"
    : "production";

  useEffect(() => {
    if (projectSearchKey == null || userSearchKey == null) return;
    setProjectsClient(getSearchClient(projectSearchKey));
    setUsersClient(getSearchClient(userSearchKey));
  }, [userSearchKey, projectSearchKey]);

  const distSearchResults = useCallback(
    async (searchTerm) => {
      const collection = `${env}.projects`;
      if (usersClient == null) return [];
      const results = await projectsClient
        .collections(collection)
        .documents()
        .search({
          q: searchTerm && searchTerm.length > 0 ? searchTerm : "*",
          per_page: 10,
          query_by: "distributor",
          include_fields: "distributor, accountNumber",
          sort_by: `distributor:asc`,
        });
      let parsedResults = results.hits
        .map((hit) => hit.document)
        .filter((result) => result.distributor !== "xxxx");
      // Create a Map to track unique distributors by name and accountNumber
      const uniqueMap = new Map();
      parsedResults.forEach((result) => {
        const key = `${result.distributor}-${result.accountNumber}`;
        uniqueMap.set(key, result);
      });
      const uniqueResults = Array.from(uniqueMap.values());
      return uniqueResults.map((result) => ({
        value: result,
        label: result.distributor,
      }));
    },
    [env, projectsClient, usersClient],
  );

  const distRepSearchResults = useCallback(
    async (searchTerm, field) => {
      const collection = `${env}.projects`;
      if (usersClient == null) return [];
      const results = await projectsClient
        .collections(collection)
        .documents()
        .search({
          q: searchTerm && searchTerm.length > 0 ? searchTerm : "*",
          per_page: 10,
          query_by: field,
          include_fields: field,
          sort_by: `${field}:asc`,
        });
      let parsedResults = results.hits
        .map((hit) => hit.document[field])
        .filter((result) => result !== "xxxx");
      const uniqueResults = [...new Set(parsedResults)];
      return uniqueResults.map((result) => ({
        value: result,
        label: result,
      }));
    },
    [env, projectsClient, usersClient],
  );

  const collaboratorsSearchResults = useCallback(
    async (searchTerm, userID) => {
      const collection = `${env}.users`;
      if (usersClient == null) return [];
      const results = await usersClient
        .collections(collection)
        .documents()
        .search({
          q: searchTerm && searchTerm.length > 0 ? searchTerm : "*",
          per_page: 10,
          query_by: "name",
          sort_by: "name:asc",
        });
      let parsedResults = results.hits
        .map((hit) => hit.document)
        .filter((result) => result.id !== userID);
      return parsedResults.map((result) => ({
        value: result.id,
        label: result.name,
      }));
    },
    [env, usersClient],
  );

  const collaboratorsByIDs = useCallback(
    async (collaboratorIDs) => {
      const collection = `${env}.users`;
      if (usersClient == null) return [];
      const results = await usersClient
        .collections(collection)
        .documents()
        .search({
          q: "*",
          query_by: "name",
          filter_by: `id: [${collaboratorIDs.join(", ")}]`,
          sort_by: "name:asc",
        });
      return results.hits?.map((hit) => hit.document) || [];
    },
    [env, usersClient],
  );

  return {
    distSearchResults,
    distRepSearchResults,
    collaboratorsSearchResults,
    collaboratorsByIDs,
  };
};

export default useDistributorCollaboratorSearch;

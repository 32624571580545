import StandardLayout from "components/StandardLayout";
import LeftMenu from "./leftMenu/LeftMenu";
import { useState } from "react";
import ProjectCard from "./ProjectCard";
import useNavigation from "helpers/useNavigation";
import ProjectDetailsModal from "../../components/ProjectDetailsModal";
import { useAuth } from "database/useAuth";
import { useProjectSearch } from "database/useProjectSearch";
import { createProject } from "database/projectsWriter";
import { X } from "lucide-react";
import { useDatabaseReader } from "database/useDatabaseReader";

export const ProjectsMainPage = () => {
  const { user } = useAuth();
  const [navigate] = useNavigation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);

  const { searchResults, searchTerm, setSearchTerm, sortBy, setSortBy } =
    useProjectSearch();
  const { warehouses } = useDatabaseReader();

  const onChooseProject = (id) => {
    navigate(`/private/projects/builder/${id}`);
  };

  const onCreateProject = async (
    projectName,
    distName,
    accountNumber,
    distRep,
    collaborators,
  ) => {
    const docId = await createProject(
      projectName,
      distName,
      accountNumber,
      distRep,
      user.preferredCurrency,
      warehouses,
      collaborators,
    );
    navigate(`/private/projects/builder/${docId}`);
  };

  return (
    <StandardLayout
      isMenuOpen={isMenuOpen}
      setIsMenuOpen={setIsMenuOpen}
      leftMenuContent={
        <LeftMenu
          onMenuClose={() => setIsMenuOpen(false)}
          onCreateNewProject={() => setIsNewProjectModalOpen(true)}
        />
      }
    >
      <div className="grid h-full grid-rows-[auto_1fr]">
        <div className="grid w-full grid-rows-[auto_auto] gap-2 bg-tertiary px-3 py-2 text-base font-bold uppercase text-text md:grid-cols-[auto_min-content] md:grid-rows-1 md:gap-6">
          <div className="border-1 focus-within: grid grid-cols-[auto_1fr_auto] items-center rounded-lg border border-secondary bg-white p-2 text-sm focus-within:border-st-orange focus-within:outline focus-within:outline-1 focus-within:outline-st-orange hover:border-st-orange lg:max-w-lg">
            <div className="flex items-center justify-center pr-2">
              <img src="/search.svg" alt="search" />
            </div>
            <input
              type="text"
              className="m-0 border-0 p-[2px] text-base font-normal outline-none"
              placeholder="Search Projects..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm.length > 0 && (
              <X
                className="cursor-pointer hover:text-st-orange"
                onClick={() => setSearchTerm("")}
              />
            )}
          </div>
          <div className="flex items-center justify-end">
            <label htmlFor="sort" className="pr-1 text-xs font-light">
              Sort:
            </label>
            <select
              name="sorting"
              id="sort"
              onChange={(e) => setSortBy(e.target.value)}
              className="border-1 rounded-md border-secondary bg-white p-1 text-sm font-light hover:border-st-orange focus:outline-none focus:outline-1 focus:outline-st-orange"
              value={sortBy}
            >
              <option value="createdAt:desc">Date Created</option>
              <option value="modifiedAt:desc">Date Modified</option>
              <option value="name:asc">Alphabetical</option>
            </select>
          </div>
        </div>
        <div className="grid-cols-[grid-cols-[repeat(auto-fit,_1fr)] grid h-full w-full auto-rows-min gap-2 overflow-y-auto bg-secondary p-2 pb-14 md:grid-cols-[repeat(auto-fit,_minmax(max-content,_49.3%))] lg:grid-cols-[repeat(auto-fit,_minmax(max-content,_32.8%))]">
          {searchResults.projects.map((project) => (
            <ProjectCard
              key={project.id}
              projectData={project}
              onClick={() => onChooseProject(project.id)}
            />
          ))}
        </div>
      </div>
      <ProjectDetailsModal
        isOpen={isNewProjectModalOpen}
        onClose={() => setIsNewProjectModalOpen(false)}
        title="New Project"
        buttonTitle="Create"
        onSave={onCreateProject}
      />
    </StandardLayout>
  );
};
